<template>
  <app-layout>
    <div class="pb-52 pt-80 flex items-center relative">
      <div class="container mx-auto xl:px-10 lg:px-8 px-5">
        <div class="xl:w-4/5 lg:w-10/12 mx-auto text-center">
          <div class="lg:text-8xl md:text-7xl sm:text-6xl text-5xl font-bold leading-none mb-7">Connecting the curb to new insights</div>
          <div class="py-3"></div>
          <h5 class="text-gray">Connecting the curb to new insights</h5>
          <div class="py-5"></div>
        </div>
        <div @click="goToSection('section2')" class="absolute bottom-10 left-0 right-0 mx-auto h-16 w-10 border pt-8 border-lightgray text-lightgray rounded-3xl animate-bounce cursor-pointer">
          <div class=" w-5 h-5 m-auto">
            <svg class="icon-svg rotate-90">
              <use href="#right_arrow"/>
            </svg>
          </div> 
        </div>
      </div>
    </div>

    <div id="section2" class="section bg-light relative">
      <div class="xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5">
        <div class="flex xl:relative">
          <div class="xl:w-1/2 lg:py-32 py-24 relative z-10">
            <h3 class="font-bold mb-4">Get to know The curb better </h3>
            <div class="p-1"></div>
            <p class="text-lg">Vade replaces legacy parking infrastructure with wireless cameras, computer vision, and granular analytics. We make it easy for cities to monitor and manage curb space in real-time.</p>
            <div class="xl:py-10 py-7"></div>
            <button class="btn border-black text-black border-4 hover:bg-primary hover:text-white hover:shadow">Contact Us</button>
          </div>
          <div class="w-2/3 xl:opacity-100 xl:bg-contain bg-right-top bg-no-repeat absolute right-0 top-0 bottom-0 opacity-20" :style="{backgroundImage:'url(/assets/graphic1.svg)'}"></div>
        </div>
      </div>
    </div>

    <div id="section3" class="section bg-primary text-white pt-32" :style="{backgroundImage:'url(/assets/bg1.svg)'}">
      <div class="xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5">
        <div class="xl:w-9/12">
          <h3 class="font-bold mb-8">Collect, analyze, and visualize occupancy data</h3>
          <div class="text-lg">Visualize demand with dashboards for curb utilization and compliance.</div>
        </div>
        <div class="py-16"></div>
        <div class="grid xl:grid-cols-3 lg:grid-cols-2 gap-16">
          <div class="col">
            <img src="/assets/icon-ux.svg" alt="">
            <h5 class="font-bold mb-4 pt-10">User experience</h5>
            <p>Vade replaces legacy parking infrastructure with wireless cameras, computer vision, and granular analytics. We make it easy for cities to monitor and manage curb space in real-time.</p>
          </div>
          <div class="col">
            <img src="/assets/icon-ui.svg" alt="">
            <h5 class="font-bold mb-4 pt-10">User interface</h5>
            <p>Vade replaces legacy parking infrastructure with wireless cameras, computer vision, and granular analytics. We make it easy for cities to monitor and manage curb space in real-time.</p>
          </div>
        </div>
        <div class="py-6"></div>
        <img src="/assets/graphic2.png" alt="">
      </div>
    </div>

    <div id="section4" class="section py-32">
      <div class="xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5">
        <h6 class="font-semibold mb-12">Here's who we've worked with</h6>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-6 text-center">
          <div class="col">
            <img src="/assets/logo-1.svg" alt="" class="h-10 mx-auto">
          </div>
          <div class="col">
            <img src="/assets/logo-2.svg" alt="" class="h-10 mx-auto">
          </div>
          <div class="col">
            <img src="/assets/masjar-logo.png" alt="" class="h-10 mx-auto">
          </div>
          <div class="col">
            <img src="/assets/logo-3.svg" alt="" class="h-10 mx-auto">
          </div>
        </div>
      </div>
    </div>
    
    <div id="section4" class="section  pb-32">
      <div class="container mx-auto xl:px-10 lg:px-8 px-5">
        <div class="2xl:w-8/12 xl:w-4/5 lg:w-10/12 mx-auto xl:px-20 lg:px-8 px-5">
          <div class="testimonial">
            <div class="testimony-card">
              <div class="md:flex items-end">
                <div class="col flex-1 pr-16 lg:pr-36 xl:pr-48">
                  <div class="text-secondary w-8 h-8 mb-3">
                    <svg class="icon-svg">
                      <use href="#icon_quote"/>
                    </svg>
                  </div>
                  <h6 class="">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. </h6>
                </div>
                <div class="col pt-8 md:pt-0">
                  <div class="avtar-wrap mb-6">
                    <img src="/assets/avatar.png" alt="" >
                  </div>
                  <div class="text-lg font-bold pb-1">Kedasgah Resjs</div>
                  <div class="text-sm text-gray font-bold">Financial Director at ABC</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="section5" class="section bg-black text-white pt-32" :style="{backgroundImage:'url(/assets/bg2.png)'}">
      <div class="xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5">
        <div class="lg:text-8xl md:text-7xl sm:text-6xl text-5xl leading-none font-bold mb-16">Connecting the curb </div>
        <div class="xl:flex mb-32">
          <div class="col pr-16 xlpb-0 pb-4">
            <h5 class="font-bold mb-4">Collect, analyze, and occupancy data</h5>
          </div>
          <div class="col">
            <div class="flex flex-col sm:grid grid-cols-2 sm:gap-20 gap-6">
              <div class="col">
                <div class="flex items-center">
                  <div class="col">
                    <div class="w-12 h-12 rounded-2xl bg-white flex justify-center items-center">
                      <img src="/assets/icon-download.svg" alt="">
                    </div>
                  </div>
                  <div class="col pl-4">
                    <div class="whitespace-nowrap">Mobile app</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="flex items-center">
                  <div class="col">
                    <div class="w-12 h-12 rounded-2xl bg-primary flex justify-center items-center">
                      <img src="/assets/icon-chart.svg" alt="">
                    </div>
                  </div>
                  <div class="col pl-4">
                    <div class="whitespace-nowrap">Web pages</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="aps-demo relative">
        <div class="aps-demo-shadow bg-gradient-to-t from-black absolute bottom-0 left-0 right-0 top-0 z-10"></div>
        <img src="/assets/aps-demo.png" alt="" class="mx-auto">
      </div>
    </div>
    
    <div id="section6" class="section bg-black text-white lg:pb-36 pb-10 pt-36 relative" >
      <div class="arrow-graphic absolute top-2 left-0 right-0 pointer-events-none">
        <img src="/assets/arrow.svg" alt="" class="mx-auto xl:w-auto w-36">
      </div>
      <div class="xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5">
        <div class="lg:flex items-center justify-between w-full">
          <div class="col lg:pr-10">
            <h5 class="font-bold mb-4 xl:w-2/3 pr-10">Supports all popular platforms</h5>
            <p class="text-sm">Our portfolio is under re-development currently. Here is a short list of our work:</p>
          </div>
          <div class="col">
            <div class="flex gap-3 pt-6 lg:pt-0">
              <div class="col">
                <img src="/assets/figma.png" alt="" class="mx-auto h-10">
              </div>
              <div class="col">
                <img src="/assets/adobe-xd.png" alt="" class="mx-auto w-10">
              </div>
              <div class="col">
                <img src="/assets/adobe-photoshop.png" alt="" class="mx-auto w-10">
              </div>
              <div class="col">
                <img src="/assets/adobe-illustrator.png" alt="" class="mx-auto w-10">
              </div>
              <div class="col">
                <img src="/assets/wordpress-blue.png" alt="" class="mx-auto w-10">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="section7" class="section py-20">
      <div class="xl:w-2/5 xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5">
        <div class="pb-10">
          <h3 class="font-bold mb-4">Frequently asked questions</h3>
        </div>
        <div class="qa-wraps flex flex-col gap-2">
          <div class="qa-item" v-for="(item, index) in qa" :key="index">
            <div class="qa-header" :class="{'rounded-b-none': item.active, 'rounded-b-3xl': !item.active}" @click="item.active = !item.active">
              <div class="col">{{item.name}}</div>
              <div class="col">
                <div class="w-3 h-3 transition-transform" :class="{'-rotate-180': item.active}">
                  <svg class="icon-svg">
                    <use href="#down_arrow"/>
                  </svg>
                </div>
              </div>
            </div>
            <slide-up-down v-model="item.active" :duration="200">
              <div class="qa-body">
                <p>{{item.message}}</p>
              </div>
            </slide-up-down>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script setup>
  import AppLayout from '@/layout/AppLayout.vue';
  // const gotoContact = () => {
  //     document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  // }
  import { ref } from 'vue'
  const qa = ref([
    {name: 'What’s included in the licenses?', message: 'Fusce eget scelerisque ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis id vulputate neque. Donec lobortis volutpat ipsum, vel ultrices neque. Ut rutrum justo ut lorem tempus, ac laoreet lorem viverra.', active:false},
    {name: 'What’s included in the licenses?', message: 'Fusce eget scelerisque ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis id vulputate neque. Donec lobortis volutpat ipsum, vel ultrices neque. Ut rutrum justo ut lorem tempus, ac laoreet lorem viverra.', active:false},
    {name: 'What’s included in the licenses?', message: 'Fusce eget scelerisque ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis id vulputate neque. Donec lobortis volutpat ipsum, vel ultrices neque. Ut rutrum justo ut lorem tempus, ac laoreet lorem viverra.', active:false},
    {name: 'What’s included in the licenses?', message: 'Fusce eget scelerisque ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis id vulputate neque. Donec lobortis volutpat ipsum, vel ultrices neque. Ut rutrum justo ut lorem tempus, ac laoreet lorem viverra.', active:false}
  ]);
  const goToSection = (targetElement) => {
    var element = document.getElementById(targetElement);
    var headerOffset = 60;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }
</script>
<style lang="postcss">
</style>