<template>
    <div class="hidden">
        <svg xmlns="http://www.w3.org/2000/svg">
            <symbol id="right_arrow" viewBox="0 0 16 8.039">
                <path id="Path_21" data-name="Path 21" d="M15.894,131.133h0L12.258,127.5a.364.364,0,1,0-.513.513l3.014,3.018H.364a.364.364,0,0,0,0,.727h14.4l-3.014,3.014a.364.364,0,1,0,.513.513l3.636-3.636A.364.364,0,0,0,15.894,131.133Z" transform="translate(0 -127.369)"/>
            </symbol>
            <symbol id="down_arrow" viewBox="0 0 9.85 5.632">
                <path id="down-arrow" d="M1055.549,6166.681l4.571,4.571,4.572-4.571" transform="translate(-1055.196 -6166.328)" fill="none" stroke="#000" stroke-width="1"/>
            </symbol>
            <symbol id="check" viewBox="0 0 18 18">
                <g id="Group_7761" data-name="Group 7761" transform="translate(-218 -2413)">
                <rect id="Rectangle_35" data-name="Rectangle 35" width="18" height="18" transform="translate(218 2413)" fill="transparent"/>
                <g id="_6-Check" data-name="6-Check" transform="translate(187 2356.842)">
                    <path id="Path_27" data-name="Path 27" d="M38.565,71.838a.316.316,0,0,1-.232-.1l-6.25-6.76a.316.316,0,0,1,.232-.53h3.008a.316.316,0,0,1,.238.109l2.089,2.4a11.967,11.967,0,0,1,1.43-2.265,21.265,21.265,0,0,1,6.85-5.5.316.316,0,0,1,.342.528,20.645,20.645,0,0,0-3.005,3.112,23.21,23.21,0,0,0-4.4,8.763.316.316,0,0,1-.306.24Z" fill="#9fd312"/>
                </g>
                </g>
            </symbol>
            <symbol id="icon_quote" viewBox="0 0 48.571 38.546">
                <path id="quote" d="M57.732-126.009,48.139-87.463h-12.7l12.186-38.546Zm-26.273,0L22.125-87.463H9.161l12.1-38.546Z" transform="translate(-9.161 126.009)" fill="#5159e0"/>
            </symbol>
            <symbol id="icon_call" viewBox="0 0 32 32">
                <path d="M23.407 30.394c-2.431 0-8.341-3.109-13.303-9.783-4.641-6.242-6.898-10.751-6.898-13.785 0-2.389 1.65-3.529 2.536-4.142l0.219-0.153c0.979-0.7 2.502-0.927 3.086-0.927 1.024 0 1.455 0.599 1.716 1.121 0.222 0.442 2.061 4.39 2.247 4.881 0.286 0.755 0.192 1.855-0.692 2.488l-0.155 0.108c-0.439 0.304-1.255 0.869-1.368 1.557-0.055 0.334 0.057 0.684 0.342 1.068 1.423 1.918 5.968 7.55 6.787 8.314 0.642 0.6 1.455 0.685 2.009 0.218 0.573-0.483 0.828-0.768 0.83-0.772l0.059-0.057c0.048-0.041 0.496-0.396 1.228-0.396 0.528 0 1.065 0.182 1.596 0.541 1.378 0.931 4.487 3.011 4.487 3.011l0.050 0.038c0.398 0.341 0.973 1.323 0.302 2.601-0.695 1.327-2.85 4.066-5.079 4.066zM9.046 2.672c-0.505 0-1.746 0.213-2.466 0.728l-0.232 0.162c-0.827 0.572-2.076 1.435-2.076 3.265 0 2.797 2.188 7.098 6.687 13.149 4.914 6.609 10.532 9.353 12.447 9.353 1.629 0 3.497-2.276 4.135-3.494 0.392-0.748 0.071-1.17-0.040-1.284-0.36-0.241-3.164-2.117-4.453-2.988-0.351-0.238-0.688-0.358-0.999-0.358-0.283 0-0.469 0.1-0.532 0.14-0.104 0.111-0.39 0.405-0.899 0.833-0.951 0.801-2.398 0.704-3.424-0.254-0.923-0.862-5.585-6.666-6.916-8.459-0.46-0.62-0.641-1.252-0.538-1.877 0.187-1.133 1.245-1.866 1.813-2.26l0.142-0.099c0.508-0.363 0.4-1.020 0.316-1.242-0.157-0.414-1.973-4.322-2.203-4.781-0.188-0.376-0.336-0.533-0.764-0.533z"></path>
            </symbol>
            <symbol id="icon_call_filled" viewBox="0 0 512 512">
                <path d="M391,480c-19.52,0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91,298.77,93.61,267.79,61,208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82,73.38,58.16,62.65,74.11,52A176.3,176.3,0,0,1,102.75,36.8c1-.43,1.93-.84,2.76-1.21,4.95-2.23,12.45-5.6,21.95-2,6.34,2.38,12,7.25,20.86,16,18.17,17.92,43,57.83,52.16,77.43,6.15,13.21,10.22,21.93,10.23,31.71,0,11.45-5.76,20.28-12.75,29.81-1.31,1.79-2.61,3.5-3.87,5.16-7.61,10-9.28,12.89-8.18,18.05,2.23,10.37,18.86,41.24,46.19,68.51s57.31,42.85,67.72,45.07c5.38,1.15,8.33-.59,18.65-8.47,1.48-1.13,3-2.3,4.59-3.47,10.66-7.93,19.08-13.54,30.26-13.54h.06c9.73,0,18.06,4.22,31.86,11.18,18,9.08,59.11,33.59,77.14,51.78,8.77,8.84,13.66,14.48,16.05,20.81,3.6,9.53.21,17-2,22-.37.83-.78,1.74-1.21,2.75a176.49,176.49,0,0,1-15.29,28.58c-10.63,15.9-21.4,28.21-39.38,36.58A67.42,67.42,0,0,1,391,480Z"/>
            </symbol>
            <symbol id="icon_list_dot" viewBox="0 0 9.023 7.336">
                <path id="list-dot" d="M2.883,0,4.57-7.336h7.336L10.219,0Z" transform="translate(-2.883 7.336)"/>
            </symbol>
        </svg>
    </div>
</template>