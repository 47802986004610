import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './main.css'
import SlideUpDown from 'vue3-slide-up-down'
import SwiperClass, { /* swiper modules... */ } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import swiper module styles
import 'swiper/css'
// more module style...

// use swiper modules
SwiperClass.use([/* swiper modules... */])
createApp(App).use(store).use(router).component('slide-up-down', SlideUpDown).use(VueAwesomeSwiper).mount('#app')
