<template>
    <div class="page-wrapper">
        <!-- header -->
        <div class="header-wrapper transition" :class="{'header-wrapper--scrolled': scrolled}">
            <div class="lg:container mx-auto xl:px-10 lg:px-8 px-5">
                <div class="flex md:justify-between items-center">
                    <div class="col md:w-1/4 order-3 md:order-none">
                        <div>
                            <button class="relative group align-top z-20" @click="toggleNavigation">
                                <div class="relative flex overflow-hidden items-center justify-center rounded-lg w-[50px] transform transition-all md:border-4  border-black" :class="{'h-[50px]': !scrolled, 'h-[40px]': scrolled}">
                                    <div class="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
                                        <div class="bg-black h-[2px] w-7 transform transition-all duration-300 origin-left" :class="{'translate-x-10': navOpen}"></div>
                                            <div class="bg-black h-[2px] w-7 rounded transform transition-all duration-300 delay-75" :class="{'translate-x-10': navOpen}"></div>
                                            <div class="bg-black h-[2px] w-7 transform transition-all duration-300 origin-left delay-150" :class="{'translate-x-10': navOpen}"></div>
                                            <div class="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10 flex w-0 " :class="{'translate-x-0 w-12': navOpen}">
                                            <div class="absolute bg-black h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300" :class="{'rotate-45': navOpen}"></div>
                                            <div class="absolute bg-black h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300" :class="{'-rotate-45': navOpen}"></div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col w-1/2 md:text-center order-1 md:order-none flex-1 md:flex-auto">
                        <router-link to="/" class="inline-block align-top">
                            <img src="/assets/logo.svg" alt="" class="transition-all" :class="{'h-10': scrolled, 'sm:h-16 h-10': !scrolled}">
                        </router-link>
                    </div>
                    <div class="col md:w-1/4 text-right order-2 md:order-none">
                        <button class="btn !px-3 !md:px-6 border-black align-top text-black md:border-4 hover:bg-primary hover:text-white hover:shadow" :class="{'btn--sm': scrolled}">
                            <span class="hidden sm:block font-semibold">Quick Enquiry</span>
                            <span class="sm:hidden block w-5 h-5 md:w-4 ms:h-4">
                                <svg class="icon-svg hidden md:block">
                                <use href="#icon_call"/>
                                </svg>
                                <svg class="icon-svg md:hidden">
                                <use href="#icon_call_filled"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade">
                <div class="header-overlay absolute top-0 left-0 bottom-0 right-0 bg-white bg-opacity-70 backdrop-blur-md z10" v-if="navOpen"></div>
            </transition>
        </div>
        <!-- Navigation Overlay -->
        <transition name="fade">
            <div v-if="navOpen" class="nav-overlay fixed top-0 left-0 bottom-0 right-0 bg-white bg-opacity-70 backdrop-blur-md z-40 flex justify-center items-center">
                <div class="absolute left-0 top-0 w-full h-full z-0"></div>
                <div class="text-center text-6xl font-bold text-black relative z-10">
                    <div class="py-8">
                        <router-link to="/" class="hover:text-secondary transition-colors">Home</router-link>
                    </div>
                    <div class="py-8">
                        <router-link to="/about" class="hover:text-secondary transition-colors">About</router-link>
                    </div>
                    <div class="py-8">
                        <router-link to="/portfolio" class="hover:text-secondary transition-colors">Portfolio</router-link>
                    </div>
                </div>
            </div>
        </transition>

        <slot></slot>

    </div>
    <div id="section8" class="section bg-light lg:pt-32 pt-20 pb-32 relative">
        <div class="xl:w-3/5 lg:w-10/12 mx-auto xl:px-10 lg:px-8 px-5 relative z-10">
            <div class="lg:text-8xl md:text-7xl sm:text-6xl text-5xl leading-none mb-9">Get started <span class="font-bold">with techflwoz <span class="text-primary">!</span>
                </span>
            </div>
            <p>Come learn more about us. <br>More so, come learn about yourself. </p>
            <div class="lg:py-28 py-10"></div>
            <button class="btn border-black text-black border-4 hover:bg-primary hover:text-white hover:shadow">
                <span class="font-semibold">Quick Enquiry</span>
            </button>
        </div>
        <img src="/assets/get-started-graphic.svg" alt="" class="absolute bottom-0 xl:right-20 right-0 xl:w-auto w-3/5">
    </div>
    <footer class="footer-wrapper pb-10 pt-20">
        <div class="lg:container mx-auto xl:px-10 lg:px-8 px-5">
            <div class="lg:flex gap-12 pb-20">
                <div class="col xl:w-3/5 lg:w-2/4">
                <div class="pb-6">
                    <router-link to="/" class="block">
                        <img src="/assets/techflowz-logo.svg" alt="" class="h-12">
                    </router-link>
                </div>
                <div class="py-2"></div>
                </div>
                <div class="col xl:w-2/5 lg:w-2/4">
                <div class="grid md:grid-cols-2 gap-10">
                    <div class="col">
                    <div class="mb-7">
                        <a href="mailto:info@techflowz.com" class="font-bold">info@techflowz.com</a>
                    </div>
                    <div class="flex gap-7">
                        <div class="col w-7">
                        <a href="#">
                            <img src="/assets/icon-instagram.svg" alt="">
                        </a>
                        </div>
                        <div class="col w-7">
                        <a href="#">
                            <img src="/assets/icon-twitter.svg" alt="">
                        </a>
                        </div>
                        <div class="col w-7">
                        <a href="#">
                            <img src="/assets/icon-facebook.svg" alt="">
                        </a>
                        </div>
                        <div class="col w-7">
                        <a href="#">
                            <img src="/assets/icon-linkedin.svg" alt="">
                        </a>
                        </div>
                    </div>
                    </div>
                    <div class="col">
                    <h6>Resources</h6>
                    <ul>
                        <li>
                        <a href="#0">Work</a>
                        </li>
                        <li>
                        <a href="#0">About us</a>
                        </li>
                        <li>
                        <a href="#0">Services</a>
                        </li>
                        <li>
                        <a href="#0">Contact us</a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            <div class="xl:flex gap-48 pb-16">
                <div class="col">
                <div class="lg:flex gap-10">
                    <div class="col pr-20">
                    <div class="lg:text-[200px] text-3xl text-lg font-bold leading-none mb-5 lg:mb-0">Ui</div>
                    </div>
                    <div class="col">
                    <div>
                        <a href="#0" class="py-1.5 inline-block">Product definition.</a>
                    </div>
                    <div>
                        <a href="#0" class="py-1.5 inline-block">Research.</a>
                    </div>
                    <div>
                        <a href="#0" class="py-1.5 inline-block">Analysis.</a>
                    </div>
                    <div>
                        <a href="#0" class="py-1.5 inline-block">Design.</a>
                    </div>
                    <div>
                        <a href="#0" class="py-1.5 inline-block">Validation.</a>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col pt-10 lg:pt-0">
                <div class="lg:flex gap-10 items-center">
                    <div class="col pr-6">
                    <div class="lg:text-[200px] text-3xl text-lg font-bold leading-none mb-5 lg:mb-0">Ux</div>
                    </div>
                    <div class="col">
                    <p>nderstand your customers and their values. … <br/>
                    Research the competition. … <br/>
                    Sketch out your product. … <br/>
                    Design your product. … <br/>
                    Implement the solution onto a webpage. … <br/>
                    Evaluate the work and improve it.</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="border-t border-blue-50"></div>
            <div class="lg:container mx-auto xl:px-10 lg:px-8 px-5">
            <div class="lg:flex justify-between text-gray pt-10">
                <div class="col">
                <div class="text-sm">Terms & conditions Privacy policy Cookies policy Copyrights Notification </div>
                </div>
                <div class="col pt-4 lg:pt-0 text-sm">
                © 2021 web design company  
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
    import { ref, onMounted, onUnmounted } from 'vue'
    const scrolled = ref(false);
    const windowScroll = () => {
        if(window.scrollY > 60) {
        scrolled.value = true
        } else {
        scrolled.value = false
        }
    }
    onMounted(() => {
        window.addEventListener("scroll", windowScroll);
    })
    onUnmounted(() => {
        window.removeEventListener("scroll", windowScroll);
    })
    const navOpen = ref(false)

    const toggleNavigation = () => {
        if(navOpen.value) {
            navOpen.value = false
        } else {
            navOpen.value = true
        }
    }
</script>
<style lang="postcss" scopped>
    .fade-enter-active,
    .fade-leave-active  {
        transition: 500ms ease-out;
    }
    .fade-enter-from,
    .fade-leave-to {
        @apply opacity-0 scale-125
    }
</style>